import { ref, Ref } from 'vue'
import { defineStore } from 'pinia'
import { useEzioAPI } from '@courseur/services'
import { BaseError, objectify } from '@courseur/utils'
import { Payout, PayoutUpdateParams, PayoutCreateParams, PayoutFetchCollectionParams, WireTransferBeneficiary, WireTransferBeneficiaryCreateParams, PayoutMethod } from './types'
import dayjs from 'dayjs'

export const usePayoutsStore = defineStore('payouts', () => {
    const { api } = useEzioAPI()

    const all: Ref<{ [id: string]: Payout }> = ref({})
    const byMonth: Ref<{ [key: string]: string[] }> = ref({})
    const pagination = ref<any>()

    const getByMonth = (params: { month: string }) => {
        return byMonth.value[params.month] ? byMonth.value[params.month].map((id) => all.value[id]) : []
    }

    const fetchCollection = async (data: any = null): Promise<Payout[]> => {
        const params = Object.assign({}, data, {
            pagination: false,
            include: 'client,alerts,sca_operation' + (data.agency_id === 'all' ? ',agency' : '')
        })
        const route = params.agency_id === 'all' ? 'payouts' : 'agencies/' + params.agency_id + '/payouts'

        const storeByMonth = params.agency_id === 'all'
        if (params.agency_id === 'all') {
            params.agency_id = null
        }
        const response = await api.get(route, params)

        store(response.data)
        if (storeByMonth) {
            const month = data.start_at ? dayjs(data.start_at).format('YYYY-MM') : 'all'
            byMonth.value = Object.assign({}, byMonth.value, {
                [month]: response.data.map((p: Payout) => p.id)
            })
        }
        return response.data
    }

    const fetchAll = async (data: { payout_method: PayoutMethod }, page: number | string = '1') => {
        const response = await api.get('/payouts', Object.assign(data, { page, per_page: 50, includes: 'alerts' }))
        byMonth.value = Object.assign({}, byMonth.value, {
            ['all']: response.data.map((p: Payout) => p.id)
        })
        pagination.value = response.meta
        store(response.data)
    }

    const fetch = async (id: string): Promise<Payout> => {
        const response = await api.get('payouts/' + id, { include: 'client,agency,alerts' })
        store([response.data])
        return response.data
    }

    const create = async (data: PayoutCreateParams): Promise<Payout> => {
        try {
            const response = await api.post('agencies/' + data.agency_id + '/payouts/', Object.assign(data, { include: 'sca_operation' }))
            store([response.data])
            return response.data
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            })
        }
    }

    const accept = async (payoutId: string) => {
        try {
            const response = await api.put('payouts/' + payoutId + '/accept', {
                include: 'client,alerts,agency'
            })
            store([response.data])
        } catch (e) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            })
        }
    }

    const refuse = async (payoutId: string) => {
        try {
            const response = await api.put('payouts/' + payoutId + '/refuse', {
                include: 'client,alerts,agency'
            })

            store([response.data])
        } catch (e) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            })
        }
    }

    const updatePayout = async (data: PayoutUpdateParams): Promise<Payout> => {
        try {
            const response = await api.put('agencies/' + data.agencyId + '/payouts/' + data.id, data)
            store([response.data])
            return response.data
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            })
        }
    }

    const createWireTransferBeneficiary = async (data: WireTransferBeneficiaryCreateParams): Promise<WireTransferBeneficiary> => {
        try {
            console.log(data)
            const response = await api.post('agencies/' + data.agency_id + '/beneficiaries', data)
            console.log(response)
            console.log(response.data)

            return response.data
        } catch (error) {
            throw new BaseError({
                title: 'Oups !',
                message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
            })
        }
    }

    // const deleteWireTransferBeneficiary = async (beneficiaryId: string) => {
    //     try {
    //         const response = await api.delete('agencies/' + beneficiaryId + '/beneficiaries')
    //         return response.data
    //     } catch (error) {
    //         throw new BaseError({
    //             title: 'Oups !',
    //             message: 'Une erreur est survenue. Veuillez contacter le support Courseur'
    //         })
    //     }
    // }

    // const storeCollection = (payouts: Payout[]) => {
    //     all.value = objectify(payouts, { __typename: 'Payout' })
    // }

    const store = (payouts: Payout[]) => {
        all.value = Object.assign({}, all.value, objectify(payouts, { __typename: 'Payout' }))
    }

    const reset = async () => {
        all.value = {}
    }

    return {
        all,
        byMonth: getByMonth,
        pagination,
        create,
        accept,
        refuse,
        updatePayout,
        createWireTransferBeneficiary,
        fetchCollection,
        fetchAll,
        fetch,
        store,
        reset
    }
})
