<template>
    <div class="notification">
        <NotificationIcon @click="opened = !opened" />
        <FloatingBox class="bottom p7" v-if="opened" @dismiss="dismiss">
            <template #content>
                <NotificationList :values="warningAlerts" />
            </template>
        </FloatingBox>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent, onMounted, ref } from 'vue'
import { useAlertsStore } from '@courseur/features/alerts/store'
// import { useUsersStore } from '@courseur/features/users/store'
import NotificationIcon from './NotificationIcon.vue'
import NotificationList from './NotificationList.vue'
const FloatingBox = defineAsyncComponent(async () => (await import('@courseur/ui')).FloatingBox)

const alertsStore = useAlertsStore()

const opened = ref(false)
const warningAlerts = computed(() => alertsStore.warningAlerts)

onMounted(async () => {
    await useAlertsStore().fetchCollection()
})

const dismiss = () => {
    opened.value = false
    // useUsersStore().notificationsOpenedAt()
}
</script>

<style lang="scss" scoped>
.notification {
    position: relative;
    .floating-box > .content > div {
        min-width: 400px;
    }
}
</style>
