import { computed, Ref, ref } from 'vue'

import { objectify } from '@courseur/utils'
import { defineStore } from 'pinia'
import { useEzioAPI } from '@courseur/services'
import { AddCategoriesParams, AddCommentParams, AddReceiptParams, ClientTransaction, RefundMethodParams, RemoveReceiptParams, Transaction, TransactionFetchCollectionParams, TransactionFetchFileParams, TransactionFetchParams } from '@courseur/features/transactions/types'
import dayjs from 'dayjs'
import { useEzioWs } from '@courseur/services'
import { SimpleClient } from 'clients/types'

export const useTransactionsStore = defineStore('transactions', () => {
    const { api } = useEzioAPI()
    const { ws } = useEzioWs()

    const all: Ref<{ [id: string]: Transaction }> = ref({})
    const byMonth: Ref<{ [key: string]: string[] }> = ref({})
    const importants: Ref<string[]> = ref([])

    const getByMonth = (params: any) => {
        return byMonth.value[params.month] ? byMonth.value[params.month].map((id) => all.value[id]) : []
    }
    const getImportants = computed(() => {
        return importants.value.map((id) => all.value[id])
    })
    const getRequireAction = computed(() => {
        const transactions = getImportants.value.filter((transaction) => transaction.status === 'creation_declined' || transaction.status === 'created' || transaction.status === 'awaiting_confirmation' || transaction.status === 'problem')
        return transactions
    })
    const getAwaiting = computed(() => {
        const transactions = getImportants.value.filter((transaction) => transaction.status === 'confirmed' || transaction.status === 'awaiting_payment')
        return transactions
    })
    const init = () => {
        ws.on('transaction:create', (payload: any) => {
            // fetch(payload.transaction_id)
        })
    }

    const fetchCollection = async (data: any = null) => {
        try {
            const include = data.include ? 'card,client,employee,'.concat(data.include) : 'card,client,employee'
            const params = Object.assign({}, data, { include, pagination: false })
            const response = await api.get('transactions', params)
            store(response.data)

            const month = data.start_at ? dayjs(data.start_at).format('YYYY-MM') : 'all'
            byMonth.value = Object.assign({}, byMonth.value, {
                [month]: response.data.map((p: Transaction) => p.id)
            })
            return response.data
        } catch (error) {
            //
        }
    }
    const fetchImportantCollection = async (agencyId: string) => {
        try {
            const response = await api.get('transactions', {
                statuses: [
                    // 'creation_declined',
                    'created',
                    'awaiting_confirmation',
                    'confirmed',
                    'awaiting_payment',
                    'problem'
                ],
                agency_id: agencyId,
                visible: true,
                include: 'card,client,employee',
                pagination: false
            })
            store(response.data)

            importants.value = response.data.map((t: Transaction) => t.id)
        } catch (error) {
            //
        }
    }

    const fetch = async (transactionId: string, data?: TransactionFetchParams) => {
        try {
            const include = 'card,client,employee,' + (data?.include || '')
            const response = await api.get('transactions/' + transactionId, { include })
            store([response.data])
        } catch (error) {
            //
        }
    }

    const fetchPdf = async (data: TransactionFetchFileParams) => {
        const params = Object.assign({}, data)
        const response = await api.getFile('transactions/pdf', params)
        return response
    }

    const fetchCsv = async (data: any) => {
        const params = Object.assign({}, data)
        const response = await api.getFile('transactions/csv?export_type=' + data.export_type, params)
        return response
    }

    const fetchXls = async (data: TransactionFetchFileParams) => {
        const response = await api.getFile('transactions/xls', data)
        return response
    }
    const fetchAccounting = async (data: TransactionFetchFileParams) => {
        const response = await api.getFile('transactions/accounting_export', data)
        return response
    }

    const update = async (data: any, type: 'transactions' | 'client_transactions' = 'transactions') => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.put(type + '/' + data.id, params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const addReceipt = async (data: AddReceiptParams) => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.post('transactions/' + data.id + '/receipts', params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const removeReceipt = async (data: RemoveReceiptParams) => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.delete('transactions/' + data.id + '/receipts', params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const confirm = async (data: any, type: 'transactions' | 'client_transactions' = 'transactions') => {
        try {
            if (type === 'transactions') {
                for (let ct of data.clientTransactions) {
                    console.log('confirm', ct.id)
                    await confirm(ct, 'client_transactions')
                }
            } else {
                console.log('confirm ct', data.id)
                const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
                const response = await api.put(type + '/' + data.id + '/confirm', params)
                store([response.data])
            }
        } catch (error) {
            //
        }
    }

    const remindEmployee = async (data: { id: string }) => {
        try {
            const params = Object.assign({}, data, { include: 'card,client,employee', pagination: false })
            const response = await api.get('transactions/' + data.id + '/employee_reminder', params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const refundMethod = async (data: RefundMethodParams) => {
        try {
            const params = Object.assign(
                {},
                {
                    payment_id: data.clientTransaction.lastPayin.id,
                    payment_type: 'manual', // deposit, automatic, manual
                    payment_method: data.method // direct_debit, credit_card, check, cash, wiretransfer
                },
                { include: 'card,client,employee', pagination: false }
            )
            const response = await api.put('client_transactions/' + data.clientTransaction.id + '/refunded', params)
            store([response.data])
        } catch (error) {
            //
        }
    }

    const assignClients = async (transaction: Transaction, clientTransactions: ClientTransaction[]) => {
        const params = Object.assign(
            {},
            {
                client_transactions: clientTransactions.map((ct) => {
                    return {
                        client_id: ct.clientId || ct.client.id,
                        amount: ct.amount
                    }
                })
            },
            { include: 'card,client,employee' }
        )
        const response = await api.post('transactions/' + transaction.id + '/clients', params)
        store([response.data])
    }
    const updateClientTransactionsClient = async (clientTransaction: ClientTransaction, client: SimpleClient) => {
        const params = Object.assign(
            {},
            {
                client_id: client.id
            },
            { include: 'card,client,employee' }
        )
        const response = await api.put('client_transactions/' + clientTransaction.id, params)
        store([response.data])
    }
    const deleteClientTransaction = async (clientTransaction: ClientTransaction) => {
        const response = await api.delete('client_transactions/' + clientTransaction.id)
        store([response.data])
    }

    const addComment = async (data: AddCommentParams) => {
        return update({
            id: data.id,
            comment: data.comment
        })
    }
    const addCategories = async (data: AddCategoriesParams) => {
        return update({
            id: data.id,
            categories: data.categories
        })
    }

    const createTransaction = async (agencyId: string) => {
        await api.post(`agencies/${agencyId}/fake_transactions`, {
            number_of_transactions: 1,
            payment_status: 'S'
        })
        // post
        // /agencies/:agencyId/fake_transactions
        // params[:number_of_transactions] toujours 1
        // params[:payment_status]
        // A = authorization_accepted,
        // S = accepted
        // I = declined
        // params[:amount]
        // params[:payment_code]
        // params[:card_id]: cardId
    }

    // const storeCollection = (transactions: Transaction[]) => {
    //     all.value = objectify(transactions)
    // }
    const store = (transactions: Transaction[]) => {
        all.value = Object.assign({}, all.value, objectify(transactions))
    }

    const reset = () => {
        all.value = {}
        byMonth.value = {}
        importants.value = []
    }
    const resetMonths = () => {
        byMonth.value = {}
    }

    return {
        all,
        byMonth: getByMonth,
        requireAction: getRequireAction,
        awaiting: getAwaiting,
        init,
        fetchCollection,
        fetchImportantCollection,
        fetch,
        fetchPdf,
        fetchCsv,
        fetchXls,
        fetchAccounting,
        update,
        addReceipt,
        addComment,
        addCategories,
        removeReceipt,
        confirm,
        remindEmployee,
        refundMethod,
        assignClients,
        deleteClientTransaction,
        updateClientTransactionsClient,
        reset,
        resetMonths,
        createTransaction
    }
})
